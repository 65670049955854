<template>
    <div>
        <LiefengContent>
            <template #title>空白栏目统计</template>
            <template #toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem>
                        <Input v-model.trim="searchData.columnName" placeholder="请输入栏目名称" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem>
                        <Input v-model.trim="searchData.columnCode" placeholder="请输入栏目编码" style="width: 160px"></Input>
                    </FormItem>
                    <Button style="margin-right: 10px" type="primary" @click="getList">查找</Button>
                    <Button style="margin-right: 10px" type="info" @click="reset">重置</Button>
                    <Button style="margin-right: 10px" type="info" @click="exportExcel">导出报表</Button>
                </Form>
            </template>
            <template #contentArea>
                <div class="container">
                    <LiefengTable ref="lftableo" :tableData="tableData" :talbeColumns="talbeColumns" :loading="loading" :hidePage="true"></LiefengTable>
                </div>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            tableData: [],
            talbeColumns: [
                {
                    title: "序号",
                    type: "index",
                    width: 80,
                    align: "center",
                },
                {
                    title: "空白栏目名称",
                    key: "columnName",
                    align: "center",
                },
                {
                    title: "空白栏目编码",
                    key: "columnCode",
                    align: "center",
                },
                {
                    title: "空白社区数",
                    key: "blankCommunityNum",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        if (params.row.blankCommunityNum && params.row.blankCommunityNum > 0) {
                                            this.$core.openLayerFrame({
                                                type: 2,
                                                title: `${params.row.columnName}`,
                                                content: `/page#/blankcolumndetail?columnCode=${params.row.columnCode}&columnName=${params.row.columnName}`,
                                                area: ["100%", "100%"],
                                            })
                                        }
                                    },
                                },
                            },
                            params.row.blankCommunityNum || 0
                        )
                    },
                },
            ],
            loading: false,
            searchData: {
                columnName: "",
                columnCode: "",
            },
        }
    },
    methods: {
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？</p>",
                onOk: () => {
                    let tHeader = ["空白栏目名称", "空白栏目编码", "空白社区数"]
                    let filterVal = ["columnName", "columnCode", "blankCommunityNum"]
                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    this.$core.exportExcel(tHeader, filterVal, tableData, "空白栏目列表")
                },
            })
        },
        changeModal() {},
        reset() {
            this.searchData = {
                columnName: "",
                columnCode: "",
            }
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/api/symanage/pc/columnTree/listCommunityByColumn", {
                columnName: this.searchData.columnName,
                columnCode: this.searchData.columnCode,
            }).then(res => {
                this.loading = false
                if (res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                }
            })
        },
    },
    created() {
        this.getList()
    },
}
</script>

<style scoped lang="less">
</style>